import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export const Header = styled.header`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`

export const Logo = styled(Img)``

export const Flag = styled(Img)`
  &.gatsby-image-wrapper {
    width: 20px;
  }
`

export const ToggleInput = styled.input.attrs({
  id: "hamburger",
  type: "checkbox",
})`
  display: none;

  @media (max-width: 768px) {
    &:checked + label {
      background: none;

      &::after,
      &::before {
        top: 0;
      }

      &::after {
        transform: rotate(45deg);
      }

      &::before {
        transform: rotate(-45deg);
      }
    }
    &:checked + label + ul {
      height: 240px;
      border-top: solid 2px black;
    }
  }
`

export const Toggle = styled.label.attrs({
  htmlFor: "hamburger",
})`
  display: none;

  @media (max-width: 768px) {
    display: block;
    margin-right: 30px;
    background: black;
    width: 20px;
    height: 3px;
    position: relative;

    &::after,
    &::before {
      top: 6px;
      position: absolute;
      content: "";
      width: 20px;
      height: 3px;
      background: black;
      transition: all 0.5s ease-in-out;
    }

    &::before {
      top: -6px;
    }
  }
`

export const NavLinks = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    height: 0;
    overflow: hidden;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 65px;
    background-color: rgba(255, 255, 255, 0.956);
    width: calc(100%);
    transition: all 0.5s ease-in-out;
  }
`

export const NavLinksItem = styled.li`
  padding: 8px 10px;

  &.flag {
    padding: 8px 4px;
  }
`

export const NavLinksLink = styled(AnchorLink)`
  font-family: "ZonaProBlack";
  text-transform: uppercase;
  text-decoration: none;
  color: rgb(81, 25, 122);

  &:visited {
    color: rgb(81, 25, 122);
  }
`
