import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useWindowScroll } from "react-use"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"

import * as S from "./styles"

const Header = () => {
  const dispatch = useContext(GlobalDispatchContext)
  const { language } = useContext(GlobalStateContext)
  const { y } = useWindowScroll()
  let headerHeight = y < 100 ? "94px" : "65px"
  let imageWidth = y < 100 ? "120px" : "90px"
  let menuTop = y < 100 ? "94px" : "65px"
  const { logoImage, es, en, pt } = useStaticQuery(
    graphql`
      query {
        logoImage: file(
          relativePath: { eq: "acai-bello-fruto-logo-menu.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        es: file(relativePath: { eq: "es.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pt: file(relativePath: { eq: "pt.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        en: file(relativePath: { eq: "en.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const home = {
    pt: "Home",
    en: "Home",
    es: "Inicio",
  }

  const quemSomos = {
    pt: "Sobre",
    en: "About",
    es: "Acerca de",
  }

  const oAcai = {
    pt: "O Açaí",
    en: "The Açaí",
    es: "El Açaí",
  }

  const nossaLinha = {
    pt: "Produtos",
    en: "Products",
    es: "Productos",
  }

  const contato = {
    pt: "Contato",
    en: "Contact",
    es: "Contacto",
  }

  return (
    <S.Header
      style={{
        height: headerHeight,
        background: `white`,
        transition: `all 0.5s`,
      }}
    >
      <S.Container>
        <S.Logo
          style={{
            width: imageWidth,
            transition: "all 0.5s",
          }}
          fluid={logoImage.childImageSharp.fluid}
        />
        <nav>
          <S.ToggleInput />
          <S.Toggle />
          <S.NavLinks style={{ top: menuTop }}>
            <S.NavLinksItem>
              <S.NavLinksLink to="/">{home[language]}</S.NavLinksLink>
            </S.NavLinksItem>
            <S.NavLinksItem>
              <S.NavLinksLink to={`/#quemsomos`}>
                {quemSomos[language]}
              </S.NavLinksLink>
            </S.NavLinksItem>
            <S.NavLinksItem>
              <S.NavLinksLink to={`/#acai`}>{oAcai[language]}</S.NavLinksLink>
            </S.NavLinksItem>
            <S.NavLinksItem>
              <S.NavLinksLink to={`/produtos`}>
                {nossaLinha[language]}
              </S.NavLinksLink>
            </S.NavLinksItem>
            <S.NavLinksItem>
              <S.NavLinksLink to={`/#contato`}>
                {contato[language]}
              </S.NavLinksLink>
            </S.NavLinksItem>
            {language !== "pt" && (
              <S.NavLinksItem className="flag">
                <a href="#" onClick={() => dispatch({ type: "pt" })}>
                  <S.Flag fluid={pt.childImageSharp.fluid} />
                </a>
              </S.NavLinksItem>
            )}
            {language !== "en" && (
              <S.NavLinksItem className="flag">
                <a href="#" onClick={() => dispatch({ type: "en" })}>
                  <S.Flag fluid={en.childImageSharp.fluid} />
                </a>
              </S.NavLinksItem>
            )}
            {language !== "es" && (
              <S.NavLinksItem className="flag">
                <a href="#" onClick={() => dispatch({ type: "es" })}>
                  <S.Flag fluid={es.childImageSharp.fluid} />
                </a>
              </S.NavLinksItem>
            )}
          </S.NavLinks>
        </nav>
      </S.Container>
    </S.Header>
  )
}

export default Header
